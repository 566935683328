import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, first } from 'rxjs/operators';

//AngularFireAuth
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as firebase from 'firebase/compat/app';
import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';

// types
import { User } from '../models/User';
import { empty, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { UserService } from './user.service';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: Observable<firebase.default.User | null>;
    subscription: Subscription = new Subscription();
    constructor(
        private http: HttpClient,
        private userService: UserService,
        private afAuth: AngularFireAuth,
        private route: ActivatedRoute,
        //private emailService: EmailService,
        private router: Router
    ) {
        this.user = afAuth.authState;
    }

    get authStatus(): Observable<any> {
        return this.afAuth.authState;
    }

    /**
     * Returns the email verification state
     */

    get emailVerificationState() {
        return this.user
            .pipe(switchMap((user: any) => {

                if (user) {
                    return user.emailVerified;
                } else {
                    //Navigate to signup
                    return false;
                }
            }));
    }

    verifyEmail() {
        return this.user.pipe().subscribe((user) => {
            return user.sendEmailVerification();
        });
    }

    /**
     * Returns the current user
     */

    get currentUser(): Observable<User | null> {
        return this.user
            .pipe(switchMap(user => {

                if (user) {
                    return this.userService.getUser(user.uid).valueChanges();
                } else {
                    //Navigate to signup
                    return empty();
                }
            }));
    }

    /**
     * Logout the user
     */
    logout(): void {
        // remove user from session storage to log user out
        this.afAuth.signOut();
        sessionStorage.removeItem('currentUser');
        this.user == null;
        //navigate to return url
        localStorage.getItem('returnUrl');

        //this.router.navigate(['/account/login']);
    }

    successCallback(data: FirebaseUISignInSuccessWithAuthResult) {
        if (data) {
            if (data.authResult) {
                if (data.authResult.user) {
                    if (data.authResult.user.metadata) {
                        if (data.authResult.user.metadata.creationTime === data.authResult.user.metadata.lastSignInTime) {
                            this.userService.initAuthProfileSave(data.authResult.user);
                            const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
                            localStorage.setItem('returnUrl', returnUrl);
                            //window.location.href = '/';

                        } else {
                            this.router.navigate(['/']);
                        }
                    }
                }
            }
        }
    }

    errorCallback(data: FirebaseUISignInFailure) {
        console.warn('errorCallback', data.code);
    }

    uiShownCallback() { }

}

