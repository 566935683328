import { MenuItem } from '../models/menu.model';

// menu items
export const MENU: MenuItem[] = [
    { key: 'ds-navigation', label: 'Navigation', isTitle: true },
    { key: 'ds-ask-home', label: 'Home', isTitle: false, icon: 'uil-home-alt', link: '/' },
    { key: 'ds-ask-question', label: 'Ask a question', isTitle: false, icon: 'uil-comment-alt-question', link: '/platform/ask-question' },
    { key: 'ds-question', label: 'Questions', isTitle: false, icon: 'uil-comments', link: '/platform/question-list' },
    // {
    //     key: 'ds-platform', label: 'Tags', isTitle: false, icon: 'uil-tag-alt', collapsed: false,
    //     children: [
    //         //{ key: 'ds-question-list', label: 'Questions', link: '/platform/question-list', parentKey: 'platform' },
    //         // { key: 'ds-question', label: 'Question', link: '/platform/question', parentKey: 'platform' },
    //         // { key: 'ds-answer', label: 'Answer', link: '/platform/answer', parentKey: 'platform' },

    //         { key: 'ds-farming-category', label: 'Category', link: '/platform/farming-category', parentKey: 'platform' },
    //         { key: 'ds-related-projects', label: 'Projects', link: '/platform/related-project', parentKey: 'platform' },
    //         { key: 'ds-location', label: 'Locations', link: '/platform/location', parentKey: 'platform' },
    //         //{ key: 'ds-tags', label: 'Tags', link: '/platform/tags', parentKey: 'platform' },
    //     ]
    // },
    { key: 'question-review', label: 'Pending Review', isTitle: false, icon: 'uil-file-exclamation', link: 'platforms/question-review' },
    // { key: 'user', label: 'Users', isTitle: false, icon: 'uil-users-alt', link: 'platforms/community'},

    //{ key: 'landing', label: 'About', isTitle: false, icon: 'uil-globe', link: '/landing' },
]

export const CLIENT_MENU: MenuItem[] = [
    { key: 'ds-navigation', label: 'Navigation', isTitle: true },
    { key: 'ds-dashboard', label: 'Dashboard', isTitle: false, icon: 'uil-home-alt', link: '/client/dashboard' },
    { key: 'ds-orders', label: 'Orders', isTitle: false, icon: 'uil-home-alt', link: '/client/order' },
    { key: 'ds-payments', label: 'Payments', isTitle: false, icon: 'uil-comment-alt-question', link: '/client/payments' },
    { key: 'ds-invoice', label: 'Account Settings', isTitle: false, icon: 'uil-comments', link: '/client/account-settings' },
    { key: 'ds-profile', label: 'Profile', isTitle: false, icon: 'uil-comments', link: '/client/profile' },
]

export const STAFFS_MENU: MenuItem[] = [
    { key: 'ds-navigation', label: 'Navigation', isTitle: true },
    { key: 'ds-dashboard', label: 'Dashboard', isTitle: false, icon: 'uil-home-alt', link: '/staff/dashboard' },
    {
        key: 'ds-platform', label: 'User', isTitle: false, icon: 'uil-tag-alt', collapsed: false,
        children: [
            { key: 'ds-dashboard', label: 'Profile', isTitle: false, icon: 'uil-home-alt', link: '/staff/user/profile' },
            { key: 'ds-dashboard', label: 'Tasks', isTitle: false, icon: 'uil-home-alt', link: '/staff/user/tasks' },
            { key: 'ds-dashboard', label: 'Messages', isTitle: false, icon: 'uil-home-alt', link: '/staff/user/messages' },
            { key: 'ds-dashboard', label: 'Earnings', isTitle: false, icon: 'uil-home-alt', link: '/staff/user/earnings' },
        ]
    },
    {
        key: 'ds-platform', label: 'Company Audit', isTitle: false, icon: 'uil-tag-alt', collapsed: false,
        children: [
            { key: 'ds-dashboard', label: 'Logs', isTitle: false, icon: 'uil-home-alt', link: '/staff/logs' },
            { key: 'ds-dashboard', label: 'Profiles', isTitle: false, icon: 'uil-home-alt', link: '/staff/profiles' },
            {
                key: 'ds-dashboard', label: 'Products', isTitle: false, icon: 'uil-home-alt', link: '/staff/products',
                children: [
                    { key: 'ds-dashboard', label: 'List', isTitle: false, icon: 'uil-home-alt', link: '/staff/products/list' },
                    { key: 'ds-dashboard', label: 'New', isTitle: false, icon: 'uil-home-alt', link: '/staff/products/new' },
                    { key: 'ds-dashboard', label: 'Tag', isTitle: false, icon: 'uil-home-alt', link: '/staff/products/tag' },
                    { key: 'ds-dashboard', label: 'Category', isTitle: false, icon: 'uil-home-alt', link: '/staff/products/category' },
                    { key: 'ds-dashboard', label: 'Part', isTitle: false, icon: 'uil-home-alt', link: '/staff/products/part' },
                    { key: 'ds-dashboard', label: 'Material', isTitle: false, icon: 'uil-home-alt', link: '/staff/products/material' },
                ]
            },
            { key: 'ds-dashboard', label: 'Shopping Cart', isTitle: false, icon: 'uil-home-alt', link: '/staff/shopping-carts' },
            { key: 'ds-dashboard', label: 'Checkout', isTitle: false, icon: 'uil-home-alt', link: '/staff/checkouts' },
            { key: 'ds-dashboard', label: 'Order', isTitle: false, icon: 'uil-home-alt', link: '/staff/orders' },
            { key: 'ds-dashboard', label: 'Payments', isTitle: false, icon: 'uil-home-alt', link: '/staff/payments' },
            { key: 'ds-dashboard', label: 'Messages', isTitle: false, icon: 'uil-home-alt', link: '/staff/messages' },
            { key: 'ds-dashboard', label: 'Tasks', isTitle: false, icon: 'uil-home-alt', link: '/staff/tasks' },
            { key: 'ds-dashboard', label: 'Earnings', isTitle: false, icon: 'uil-home-alt', link: '/staff/earnings' },
        ]
    },
]

export const ADMIN_MENU: MenuItem[] = [
    { key: 'ds-navigation', label: 'Navigation', isTitle: true },
    { key: 'ds-dashboard', label: 'Dashboard', isTitle: false, icon: 'uil-home-alt', link: '/admin/dashboard' },
    { key: 'ds-services', label: 'Services', isTitle: false, icon: 'uil-home-alt', link: '/admin/services' },
    { key: 'ds-company', label: 'Company', isTitle: false, icon: 'uil-comment-alt-question', link: '/admin/company' },
]