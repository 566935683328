import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database/';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { trace } from '@angular/fire/compat/performance';
import * as firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { User } from '../models/User';
import { AuthenticationService } from './auth.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { WebsiteMessage } from '../models/WebsiteMessage';


@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(
    private db: AngularFireDatabase,
    private fns: AngularFireFunctions,
    private storage: AngularFireStorage,
  ) { }

  async updateUser(userProfile: User) {
    if (userProfile && userProfile.userId) {
      try {
        return await this.db.object(`/user/${userProfile.userId}`).update(userProfile).then((result) => {
          return true;
        });
      } catch (err) {
        return err;
      }
    }
  }

  getUser(userId: string): AngularFireObject<User> {
    return this.db.object('/user/' + userId);
  }

  initAuthProfileSave(user: firebase.default.User) {
    let displayName = "";
    let firstName = "";
    let lastName = "";
    let surName = "";
    let email = "";
    let userId = "";
    let phoneNumber = "";

    if (user.displayName) {
      displayName = user.displayName

      let nameArray = user.displayName.split(" ");

      if (nameArray.length > 0) { firstName = nameArray[0]; }
      if (nameArray.length > 1) { lastName = nameArray[1]; }
      if (nameArray.length > 2) { surName = nameArray[2]; }
    };

    if (user.email) { email = user.email };
    if (user.uid) { userId = user.uid };
    if (user.phoneNumber) { phoneNumber = user.phoneNumber };

    return this.db.object('/user/' + userId).update({
      userId: userId,
      displayName: displayName,
      firstName: firstName,
      lastName: lastName,
      surName: surName,
      personalContact: {
        phoneNumber: phoneNumber,
        email: email,
      },
    });
    //Add role code - backend
  }

  registerNewUser(load: any) {
    const callable = this.fns.httpsCallable('registerNewUser');
    return callable({
      user: load,
    });
  }

  uploadProfileImage(event, path: string): Observable<any> {
    let downloadURL = "";
    let uploadPercent = 0;
    const file = event.target["files"][0];
    const filePath = path;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    return Observable.create(observer => {
      task.percentageChanges().subscribe((result) => {
        uploadPercent = result;
        observer.next(
          {
            uploadProgress$: result,
            downloadUrl$: downloadURL,
          }
        );
      })

      // get notified when the download URL is available
      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((result) => {
            downloadURL = result;
            observer.next(
              {
                uploadProgress$: uploadPercent,
                downloadUrl$: result,
              }
            );
            observer.complete();
          })
        })
      )
        .subscribe()
    })

  }

  sendMessage(messageLoad: WebsiteMessage) {
    const callable = this.fns.httpsCallable('websiteMessage');
    return callable({
      type: 'question-id',
      name: messageLoad.name,
      subject: messageLoad.subject,
      message: messageLoad.message,
      email: messageLoad.email,
      phoneNumber: messageLoad.phoneNumber
    });
  }

  //Correct method 
  submitLandingPageMessage(load: any) {
    try {
      return this.db.object(`/messages/anonymous`).update(load).then((result) => {
        return true;
      });
    } catch (err) {
      return err;
    }
  }
}
