// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'miyagi-global-b1426',
    appId: '1:993172733445:web:48a93c0d38dc44ed82266c',
    databaseURL: 'https://miyagi-global-b1426.firebaseio.com',
    storageBucket: 'miyagi-global-b1426.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyB3RE31wI6HPTFXlUI4K9Cj0316KNTxlF0',
    authDomain: 'miyagi-global-b1426.firebaseapp.com',
    messagingSenderId: '993172733445',
    measurementId: 'G-XCLFSN6MXD',
  },
  production: false,
  demo: '', // other possible options are, saas creative and modern
  GOOGLE_MAPS_API_KEY: 'AIzaSyC5pcAkhXSo6-KqV9RtZdUAhdJno7r_8b0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
