export const environment = {
  firebase: {
    projectId: 'miyagi-global-b1426',
    appId: '1:993172733445:web:48a93c0d38dc44ed82266c',
    databaseURL: 'https://miyagi-global-b1426.firebaseio.com',
    storageBucket: 'miyagi-global-b1426.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyB3RE31wI6HPTFXlUI4K9Cj0316KNTxlF0',
    authDomain: 'miyagi-global-b1426.firebaseapp.com',
    messagingSenderId: '993172733445',
    measurementId: 'G-XCLFSN6MXD',
  },
  production: true,
  demo: '', // other possible options are, saas creative and modern
  GOOGLE_MAPS_API_KEY: 'AIzaSyC5pcAkhXSo6-KqV9RtZdUAhdJno7r_8b0'
};
