import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
    constructor(private injector: Injector) { }

    handleError(error: Error | HttpErrorResponse) {
        //const notificationServices = this.injector.get(NotificationServices);
        const router = this.injector.get(Router);
        //console.log("error: ",error);
        // if (error instanceof HttpErrorResponse) {
        //     //Server of connection error happened

        //     if (!navigator.onLine) {
        //         //Handle offline error
        //         console.log("!navigator.onLine");

        //     } else {
        //         // Handle Http Error (error.status === 403, 404...)
        //         console.log("error.status: ", error.status);
        //     }
        // } else {
        //     //Handle Client Error (Angular Error, ReferenceError...)
        //     router.navigate(['/error/404']);
        // }
    }
}
