import { Component, OnInit } from '@angular/core';
import { NotificationService } from './core/service/notification.service';
import { Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'Miyagi Global';
  durationInSeconds = 5;
  message: any;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
  ){ }

  ngOnInit() {
    this.notificationService.requestPermission();
    this.notificationService.listen();
    this.message = this.notificationService.currentMessage;
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  
}
